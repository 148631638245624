import * as React from 'react'
import NavHeader from '../../../components/navHeaderEn'
import SiteFooter from '../../../components/footerEn'
import PageTitle from '../../../components/pageTitle'
import { Link } from 'gatsby'
import * as eGovernmentStyle from '../../../styles/eGovernment.module.scss'

const EGovernment = () => {
  return (
    <main>
    <NavHeader/>
    <PageTitle pageTitle="eGovernment"/>
        <div className="mainContainer">
        <div class="egovernment-container">
                <section className={eGovernmentStyle.egovSection}>
                    <div class="section-container">
                        <div className={eGovernmentStyle.articleHeading}>
                        <Link to="/en/eGovernment"></Link>
                            <h1>Public grants</h1>
                            
                        </div>
                        <article className={eGovernmentStyle.articleText}>
                        <h2>Scope</h2>
                        <p>Approximately 10-20% of incomes to the budget of public administration are invested by individual ministries, bureaus and self-governments via subsidies into various development projects. There are roughly 300 various providers of subsidies that provide grants based on thousands of diverse grant schemes. Grants’ data were heterogeneous, unreliable and often only on the paper or in Excel.</p>
                        <p>There was no unified system nor process for providing grants. The state had no overview on what, how much, whom and what are the public resources being invested into. Processes for grants was this system complicated, confusing and unpredictable for citizens and entrepreneurs who were asking.</p>
                    </article>
                    <article className={eGovernmentStyle.articleText}>
                        <h2>Solution</h2>

                        <p>The process of providing public grants is computerized within the reform of the public administration. Integrated IS which includes data regarding all grants in one place was created (currently it includes central bodies of state administration, self-government is in a preparation) It is possible to not only acquire online analytic data about grants, but also to fully electronically secure complex process of management of grants from the side of grant applicant (submission of a request, accounting of the grant) as well as from the side of the provider (a call notice for submitting projects, rating, transfer of resources, inspection, reporting)</p>

                    </article>
                    <article className={eGovernmentStyle.articleText}>
                        <h2>Solution benefits</h2>
                        <h3>Grant recipients :</h3>
                        <ul>
                            <li>Complex information about all grants in one place</li>
                            <li>Comfortable way executing all steps required to obtain a grant in a fully automated regime</li>
                        </ul>
                        <h3>Grant providers:</h3>
                        <ul>
                            <li>Comfortable way of providing grants both in a fully electrified and a “paper” regime
                            </li>
                            <li>Immediate complex overview about all grants
                            </li>
                            <li>Online analysis of grants to improve planning, higher efficiency and effectiveness of provided grants to fulfil target policies
                            </li>
                            <li>Risk analysis and detection of grant frauds
                            </li>
                        </ul>
                    </article>
                    </div>
                </section>
            </div>
        </div>
    <SiteFooter/>
    </main>
  )
}

export default EGovernment